import "@ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "@ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { CellClickedEvent, GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import { CACHE_LIFESPAN } from "global/components/UI/DataGrid/hooks/use-data-cache";
import MESSAGES from "global/messages";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { GridActions } from "./components/GridActions";
import "./dataGrid.scss";
import { useGridCache } from "./hooks/use-grid-cache";
import { useGridData } from "./hooks/use-grid-data";
import { DataGridProps } from "./types";

const DEFAULT_GRID_HEIGHT = 300;
const DEFAULT_GRID_DATA_CACHE_MAX_AGE = 300;

const DataGrid = <T,>(props: PropsWithChildren<DataGridProps<T>>) => {
  const [gridApi, setGridApi] = useState<GridApi>();

  const hasUncontrolledData =
    props.rowsDataDef.uncontrolledDataFetchCall !== undefined;
  const hasActions =
    props.exportDef !== undefined || props.gridActions !== undefined;

  // Setup cache
  const cacheProps = {
    cacheKey: props.rowsDataDef.cacheProps?.cacheKey ?? "",
    expireAgeInSeconds:
      props.rowsDataDef.cacheProps?.expireAgeInSeconds ??
      DEFAULT_GRID_DATA_CACHE_MAX_AGE,
    lifespan: props.rowsDataDef.cacheProps?.lifespan ?? CACHE_LIFESPAN.SHORT,
  };

  const {
    cacheData,
    dataCacheFill,
    cacheAgeProgress,
    canTriggerCacheRefresh,
    hasCachedData,
    showCacheDataActions,
  } = useGridCache<T>({
    cacheProps,
    isUncontrolledMode: hasUncontrolledData,
    refreshing: props.rowsDataDef.isFetchingData ?? false,
  });

  // Setup data management
  const { rowData, setRowData, refreshing, fetchData } = useGridData<T>({
    initialData: hasCachedData ? cacheData.data : [],
    uncontrolledFetch: props.rowsDataDef.uncontrolledDataFetchCall,
    onDataChange: props.onRowDataChanged,
    dataCacheFill,
  });

  // Event handlers
  const onRowClicked = useCallback(
    (event: CellClickedEvent) => {
      if (props.onRowClicked) {
        props.onRowClicked(event.data, event.column.getColId());
      }
    }, // eslint-disable-next-line
    [props.onRowClicked]
  );

  // Grid configuration
  const gridOptions = {
    ...props.gridOptions,
    defaultColDef: {
      width: 120,
      sortable: true,
      resizable: true,
      flex: 1,
      ...props.gridOptions?.defaultColDef,
    },
    overlayNoRowsTemplate: MESSAGES.DATAGRID_NO_ROWS,
    columnDefs: props.columnDefs,
    rowHeight: 26,
    headerHeight: 36,
    animateRows: true,
    overlayLoadingTemplate: MESSAGES.DATAGRID_LOADING,
  };

  // Handle controlled data updates
  useEffect(() => {
    if (hasUncontrolledData) return;

    if (props.rowsDataDef.isFetchingData) {
      gridApi?.showLoadingOverlay();
    } else {
      setRowData(props.rowsDataDef.data ?? []);
      if ((props.rowsDataDef.data ?? []).length === 0) {
        gridApi?.showNoRowsOverlay();
      } else {
        gridApi?.hideOverlay();
      }
    } // eslint-disable-next-line
  }, [props.rowsDataDef, hasUncontrolledData, gridApi]);

  const containerClassName = `ag-theme-alpine relativeGrid ${
    hasActions || showCacheDataActions ? "withGridActionsRow" : ""
  }`;

  return (
    <div
      className={containerClassName}
      style={{ height: props.height ?? DEFAULT_GRID_HEIGHT }}
    >
      <GridActions
        showActions={hasActions}
        showCacheActions={showCacheDataActions}
        exportDef={props.exportDef}
        gridActions={props.gridActions}
        refreshing={refreshing}
        canTriggerRefresh={canTriggerCacheRefresh}
        cacheAgeProgress={cacheAgeProgress}
        onRefresh={() => fetchData(true)}
      />

      <AgGridReact
        ref={props.dataGridRef}
        className={props.className}
        rowHeight={props.rowHeight}
        gridOptions={gridOptions}
        rowData={rowData}
        rowSelection="single"
        onCellClicked={onRowClicked}
        onRowDataUpdated={() => {
          if (props.onRowSelected) props.onRowSelected(undefined);
        }}
        onRowSelected={(event) => {
          if (props.onRowSelected && event.node.isSelected())
            props.onRowSelected(event.data);
        }}
        onGridReady={(event) => {
          setGridApi(event.api);
          if (props.onGridReady) props.onGridReady(event);
          fetchData();
        }}
      />
    </div>
  );
};

export default DataGrid;
