import { useCallback, useEffect, useRef, useState } from "react";
import { DataFetchCallbacks } from "../types";

interface UseGridDataProps<T> {
  initialData?: T[];
  uncontrolledFetch?: (params: DataFetchCallbacks<T>) => void;
  onDataChange?: (data: T[]) => void;
  dataCacheFill: (data: T[]) => void;
}

export function useGridData<T>({
  initialData = [],
  uncontrolledFetch,
  onDataChange,
  dataCacheFill,
}: UseGridDataProps<T>) {
  const [rowData, setRowData] = useState<T[]>(initialData);
  const [refreshing, setRefreshing] = useState(false);
  const mountedRef = useRef(true);
  const currentFetchId = useRef(0);

  // Setup cleanup on unmount
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const fetchData = useCallback(
    (force = false) => {
      if (!uncontrolledFetch || (!force && rowData.length > 0)) return;

      const fetchId = ++currentFetchId.current;
      setRefreshing(true);

      uncontrolledFetch({
        callbackOnSuccess: (data: T[]) => {
          // Only update if component is mounted and this is the latest fetch
          if (mountedRef.current && fetchId === currentFetchId.current) {
            setRefreshing(false);
            setRowData(data);
            dataCacheFill(data);
            onDataChange?.(data);
          }
        },
        callbackOnFail: (error: Error) => {
          // Only update if component is mounted and this is the latest fetch
          if (mountedRef.current && fetchId === currentFetchId.current) {
            setRefreshing(false);
            setRowData([]);
          }
        },
      });
    }, // eslint-disable-next-line
    [uncontrolledFetch, dataCacheFill, onDataChange]
  );

  return {
    rowData,
    setRowData,
    refreshing,
    fetchData,
  };
}
