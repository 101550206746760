import CancelIcon from "@mui/icons-material/Cancel";
import JoinFullIcon from "@mui/icons-material/JoinFull";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import { Box, Chip, Stack, Typography } from "@mui/material";
import MESSAGES from "global/messages";
import React from "react";
import {
  FilterConfig,
  FilterFieldDescriptor,
} from "./UnplausibleRechnungenFilter";

interface FilterSummaryProps {
  filterConfig: FilterConfig;
  onRemoveFilter: (field: string) => void;
  filterFields: FilterFieldDescriptor[];
}

const UnplausibleRechnungenFilterSummary: React.FC<FilterSummaryProps> = ({
  filterConfig,
  onRemoveFilter,
  filterFields,
}) => {
  if (Object.keys(filterConfig.filters).length === 0) {
    return null;
  }

  const getFieldLabel = (fieldId: string): string => {
    const field = filterFields.find((f) => f.id === fieldId);
    return field?.label || fieldId;
  };

  const filterEntries = Object.entries(filterConfig.filters);
  const LogicIcon = filterConfig.logic === "AND" ? JoinInnerIcon : JoinFullIcon;

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        borderRadius: 1,
        p: 2,
        mb: 2,
      }}
    >
      <Typography className="uiElement__Label" sx={{ mb: 1, fontWeight: 500 }}>
        {MESSAGES.UNPLAUSIBLE_RECHNUNGEN_ACTIVE_FILTERS}
      </Typography>

      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        flexWrap="wrap"
        useFlexGap
      >
        {filterEntries.map(([field, value], index) => (
          <React.Fragment key={field}>
            <Chip
              key={field}
              label={`${getFieldLabel(field)}: ${value}`}
              onDelete={() => onRemoveFilter(field)}
              deleteIcon={<CancelIcon />}
              size="small"
              sx={{
                backgroundColor: "secondary.light",
                "& .MuiChip-label": {
                  color: "secondary.contrastText",
                },
              }}
            />
            {index < filterEntries.length - 1 && (
              <LogicIcon
                sx={{
                  fontSize: 16,
                  color: "action.active",
                  mx: 0.5,
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
};

export default UnplausibleRechnungenFilterSummary;
