import { ContentCopyTwoTone } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import {
  StatusIconType,
  convertStatusValue,
} from "global/components/UI/StatusIcon/StatusIcon";
import ModalController, {
  DialogController,
} from "global/hook/modal/interface/ModalController";
import MESSAGES from "global/messages";
import AutomatService from "service/automat-service/Automat.service";
import PingResult from "service/automat-service/interface/PingResult";
import TraceRouteResult from "service/automat-service/interface/TraceRouteResult";
import CheckAutomatResponse, {
  CheckAutomat,
} from "service/data-service/automate-controller/interface/CheckAutomat";

export enum ABRUF_REMOTE_OPERATION_TYPE {
  "ping",
  "traceroute",
}

export interface RemoteOperationResultWrapper {
  error?: Error;
  result: PingResult | TraceRouteResult;
}

type StatusMapping = {
  [key: string]: string;
};

const STATUS_MAPPINGS: StatusMapping = {
  NOK: StatusIconType.NOK,
  FAILURE: StatusIconType.NOK,
  OK: StatusIconType.OK,
  SUCCESS: StatusIconType.OK,
  RN: StatusIconType.RN,
  RUNNING: StatusIconType.RN,
};

const AbrufPanelController = {
  transmuteToGridData(response: CheckAutomatResponse): Array<CheckAutomat> {
    let data: Array<CheckAutomat> = [];

    let records = response.rows ?? [];
    if (records.length === 1 && records[0].errorTyp === MESSAGES.ERR_NO_DATA) {
      return data;
    }
    records.forEach((element) => {
      const record = {
        ...element,
        ...{
          statusCellNode: convertStatusValue(
            this.reduceCheckStatus(element.status),
            false
          ),
          kommTyp: (element.kommTyp ?? "").replace("AT_", ""),
        },
      };
      data.push(record);
    });
    return data;
  },
  reduceCheckStatus(theValue: string | null): string | null {
    if (!theValue) return null;

    return STATUS_MAPPINGS[theValue.toUpperCase()] || null;
  },
  executeRemoteOperation(
    operation: ABRUF_REMOTE_OPERATION_TYPE,
    remoteIp: string,
    modalController: ModalController,
    dialogController: DialogController
  ): void {
    if (operation === ABRUF_REMOTE_OPERATION_TYPE.traceroute) {
      modalController.showLoadingDialog(MESSAGES.ABRUF_TRACEROUTE_RUNNING);
      AutomatService.trace(
        remoteIp,
        (result: TraceRouteResult) =>
          remoteOperationSummary(
            modalController,
            dialogController,
            remoteIp,
            result
          ),
        (error: Error) =>
          remoteOperationSummary(
            modalController,
            dialogController,
            remoteIp,
            {} as TraceRouteResult,
            error
          )
      );
    } else {
      modalController.showLoadingDialog(MESSAGES.ABRUF_PING_RUNNING);
      AutomatService.ping(
        remoteIp,
        "5",
        (result: PingResult) =>
          remoteOperationSummary(
            modalController,
            dialogController,
            remoteIp,
            result
          ),
        (error: Error) =>
          remoteOperationSummary(
            modalController,
            dialogController,
            remoteIp,
            {} as PingResult,
            error
          )
      );
    }
  },
};

function remoteOperationSummary(
  modalController: ModalController,
  dialogController: DialogController,
  remoteIp: string,
  result: PingResult | TraceRouteResult,
  error?: Error
) {
  // close loading/executing modal
  modalController.closeModal();
  if (error) {
    modalController.showError(MESSAGES.ABRUF_OP_ERROR, error);
  } else {
    const textColor = result.color === "red" ? "error.main" : "success.main";
    let content = (
      <Stack rowGap={2}>
        <Typography fontWeight={500} color={textColor} alignSelf="center">
          {result.resultText}
        </Typography>
        <Typography fontWeight={500} color={textColor} alignSelf="center">
          {`für ${remoteIp}`}
        </Typography>
        <Stack className="rowStack" columnGap={2}>
          <Typography fontWeight={500}>
            Technische Verbindungsinformationen:
          </Typography>
          <IconButton
            color="secondary"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(result.rawResult ?? "");
            }}
          >
            <ContentCopyTwoTone fontSize="small" />
          </IconButton>
        </Stack>
        <Box className="networkOpResultBox">
          <Typography className="networkOpResult">
            {result.rawResult}
          </Typography>
        </Box>
      </Stack>
    );
    dialogController.showDialog({
      message: "",
      content: content,
      onOkClick: () => {},
      okCaption: MESSAGES.BUTTON_OK,
    });
  }
}

export default AbrufPanelController;
