import ModalController from "global/hook/modal/interface/ModalController";
import MESSAGES from "global/messages";
import ArtikleSearchResult from "service/artikel-service/interface/ArtikleSearchResult";

export interface ArtikelSucheDisplayInfo {
  readonly artikelLangText?: string;
  readonly gueltigAb?: string;
  readonly gueltigBis?: string;
  readonly letzteRuecknahmeArtikelOfAutomat?: string;
  readonly letzteRuecknahmeAutomat?: string;
  readonly letzteRuecknhameArtikelAllg?: string;
}

type ArticleErrorType =
  | "INVALID_GTIN"
  | "ARTICLE_NOT_BILLED_YET"
  | "NO_DATA"
  | "ARTICLE_NOT_VALID_RIGHT_NOW"
  | "UNKNOWN"
  | "INVALID_DATE";

const ERROR_MESSAGES = {
  INVALID_GTIN: "Die angegebene GTIN ist nicht gültig.",
  ARTICLE_NOT_BILLED_YET:
    "Der Automat wurde mit der aktuellen SSID noch nicht abgerechnet. Eine Aussage zur Rücknahme ist daher nicht möglich.",
  NO_DATA: "Es konnten keine Daten gefunden werden.",
  ARTICLE_NOT_VALID_RIGHT_NOW: "Der Artikel ist derzeit nicht gültig.",
  UNKNOWN: (gtin?: number, machineId?: string) =>
    `Ein unbekannter Fehler ist aufgetreten (GTIN: ${gtin} / SchlüsselID: ${machineId})`,
  INVALID_DATE:
    "Das in der Datenbank stehende Datum kann nicht formatiert werden.",
  DEFAULT:
    "Ein unbekannter Fehler ist aufgetreten. Bitte melden Sie sich beim technischen Support. ",
} as const;

const ArtikelSucheController = {
  getAbrechnungsinformationenErrorText(
    errorTyp: ArticleErrorType | string,
    gtin?: number,
    machineId: string = ""
  ) {
    const message = ERROR_MESSAGES[errorTyp as keyof typeof ERROR_MESSAGES];
    if (typeof message === "function") {
      return message(gtin, machineId);
    }
    return message || ERROR_MESSAGES.DEFAULT;
  },

  transmuteSearchResultToDisplayInfo(
    result: ArtikleSearchResult,
    modals?: ModalController
  ): ArtikelSucheDisplayInfo {
    if (!result.artikel || !result.ruecknahmeInfo) {
      return {};
    }
    if (result.errorTyp !== MESSAGES.ERR_NO_ERROR) {
      if (modals)
        modals.showMessageDialog(
          this.getAbrechnungsinformationenErrorText(
            result.errorTyp,
            result.ruecknahmeInfo.gtin,
            result.ruecknahmeInfo.maschineId
          )
        );
      return {};
    }

    return {
      artikelLangText: result.artikel.artikelLangText,
      gueltigAb: result.artikel.gueltigAb,
      gueltigBis: result.artikel.gueltigBis,
      letzteRuecknahmeArtikelOfAutomat:
        result.ruecknahmeInfo.letzteRuecknahmeArtikelOfAutomat,
      letzteRuecknahmeAutomat: result.ruecknahmeInfo.letzteRuecknahmeAutomat,
      letzteRuecknhameArtikelAllg:
        result.ruecknahmeInfo.letzteRuecknhameArtikelAllg,
    };
  },
};

export default ArtikelSucheController;
