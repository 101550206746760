import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import JoinFullIcon from "@mui/icons-material/JoinFull";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Enumerator, {
  EnumeratorItem,
} from "global/components/EnumeratedValues/Enumerator";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import {
  AUTOMATENBLATT_AUTOMATHERSTELLER_REGION_ENUM_ID,
  RECHNUNGDATEN_ATH_UNTERWARENGRUPPE_ENUM_ID,
  RECHNUNGDATEN_ATH_WARENGRUPPE_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import MESSAGES from "global/messages";
import DateUtils from "global/util/DateUtils";
import RechnungnehmerRegionEnumerator from "page/administration/anlage-rechnungsnehmer/AnlagerechnungsnehmerRegionUtil";
import { useEffect, useState } from "react";

export type FilterLogic = "AND" | "OR";

// Extend field types to include select
type FieldType = "text" | "number" | "date" | "select";

export interface FilterFieldDescriptor {
  id: string;
  label: string;
  type: FieldType;
  enumId?: string; // For fields that use an enumerator
}

interface FilterLine {
  id: number;
  field: string;
  value: string;
  dateValue?: Date;
}

export interface FilterConfig {
  filters: Record<string, string>;
  logic: FilterLogic;
}

export const DEFAULT_FILTER_CONFIG: FilterConfig = {
  filters: {},
  logic: "AND",
};

interface FilterDialogContentProps {
  activeFilterConfig: FilterConfig;
  onApplyFilters: (config: FilterConfig) => void;
  onClearFilters: () => void;
}
const RegionEnumerator = {
  items: RechnungnehmerRegionEnumerator.items.map((item) => {
    return {
      id: item.id,
      source: item.source,
      value: item.id,
    } as EnumeratorItem;
  }),
} as Enumerator;

export const FILTER_FIELD_DESCRIPTORS: FilterFieldDescriptor[] = [
  { id: "eventId", label: "Vorgangs-Nr.", type: "number" },
  { id: "rnsGln", label: "RNS GLN", type: "number" },
  { id: "regulierungsBeleg", label: "Regulierungs Beleg", type: "text" },
  { id: "referenz", label: "Referenz", type: "text" },
  { id: "seriennr", label: "Seriennr", type: "number" },
  {
    id: "automatHerstellerName",
    label: "Hersteller",
    type: "select",
    enumId: AUTOMATENBLATT_AUTOMATHERSTELLER_REGION_ENUM_ID,
  },
  {
    id: "lieferantenArtikelNr",
    label: "Lieferanten ArtikelNummer",
    type: "number",
  },

  { id: "leistungsdatum", label: "Liefer -/ Leistungsdatum", type: "date" },
  { id: "belegdatum", label: "Belegdatum", type: "date" },
  {
    id: "region",
    label: "Region",
    type: "select",
    enumId: "REGION_ENUM_ID",
  },
  {
    id: "warrengruppe",
    label: "Warengruppe",
    type: "select",
    enumId: RECHNUNGDATEN_ATH_WARENGRUPPE_ENUM_ID,
  },
  {
    id: "unterwarengruppe",
    label: "Unterwarengruppe",
    type: "select",
    enumId: RECHNUNGDATEN_ATH_UNTERWARENGRUPPE_ENUM_ID,
  },
];

const UnplausibleRechnungenFilterDialogContent: React.FC<
  FilterDialogContentProps
> = ({
  activeFilterConfig = DEFAULT_FILTER_CONFIG,
  onApplyFilters,
  onClearFilters,
}) => {
  const herstellerEnum: Enumerator = useEnumerator(
    AUTOMATENBLATT_AUTOMATHERSTELLER_REGION_ENUM_ID
  );
  const warengruppeEnum = useEnumerator(RECHNUNGDATEN_ATH_WARENGRUPPE_ENUM_ID);
  const unterwarengruppeEnum = useEnumerator(
    RECHNUNGDATEN_ATH_UNTERWARENGRUPPE_ENUM_ID
  );

  const getEnumerator = (enumId: string): Enumerator => {
    switch (enumId) {
      case RECHNUNGDATEN_ATH_WARENGRUPPE_ENUM_ID:
        return warengruppeEnum;
      case RECHNUNGDATEN_ATH_UNTERWARENGRUPPE_ENUM_ID:
        return unterwarengruppeEnum;
      case AUTOMATENBLATT_AUTOMATHERSTELLER_REGION_ENUM_ID:
        return {
          ...herstellerEnum,
          items: herstellerEnum.items.filter(
            (enumItem) => enumItem.id !== "ALLE"
          ),
        } as Enumerator;
      default:
        return RegionEnumerator;
    }
  };

  const getEnumeratorSelection = (
    enumId: string,
    value: string
  ): EnumeratorItem => {
    return (
      getEnumerator(enumId).items.find((item) => item.id === value) ||
      ({
        id: "",
        value: "",
      } as EnumeratorItem)
    );
  };

  const [filterConfig, setFilterConfig] =
    useState<FilterConfig>(activeFilterConfig);
  const [filterLines, setFilterLines] = useState<FilterLine[]>([]);

  useEffect(() => {
    // Update when external config changes
    setFilterConfig(activeFilterConfig);
  }, [activeFilterConfig]);

  const updateFilters = (newFilters: Record<string, string>) => {
    setFilterConfig((prev) => ({
      ...prev,
      filters: newFilters,
    }));
  };

  const updateLogic = (newLogic: FilterLogic) => {
    setFilterConfig((prev) => ({
      ...prev,
      logic: newLogic,
    }));
  };

  // Initialize or update filter lines when active config changes
  useEffect(() => {
    if (Object.keys(activeFilterConfig.filters).length > 0) {
      const newFilterLines = Object.entries(activeFilterConfig.filters).map(
        ([field, value]): FilterLine => ({
          id: Date.now() + Math.random(),
          field,
          value,
          dateValue: DateUtils.parseIsoDateString(value) ?? undefined,
        })
      );
      setFilterLines(newFilterLines);
    } else {
      setFilterLines([{ id: Date.now(), field: "", value: "" }]);
    }
  }, [activeFilterConfig]);

  const addFilterLine = (): void => {
    setFilterLines((prev) => [
      ...prev,
      { id: Date.now(), field: "", value: "", type: "text" },
    ]);
  };

  const removeFilterLine = (id: number): void => {
    setFilterLines((prev) => prev.filter((line) => line.id !== id));
  };

  const updateFilterLine = (
    id: number,
    field: keyof FilterLine,
    value: string | number,
    dateValue?: Date
  ): void => {
    setFilterLines((prev) =>
      prev.map((line) =>
        line.id === id
          ? { ...line, [field]: value, ["dateValue"]: dateValue }
          : line
      )
    );
  };

  const handleLogicChange = (newLogic: FilterLogic) => {
    setFilterConfig((prev) => ({
      ...prev,
      logic: newLogic,
    }));
  };

  const handleInputChange = (
    id: number,
    fieldDescriptor: FilterFieldDescriptor,
    value: string
  ) => {
    var userValue = value;
    if (fieldDescriptor.type === "number" && isNaN(Number(value))) {
      userValue = "";
    }
    updateFilterLine(id, "value", userValue);
  };

  const handleSelectChange = (id: number, newFieldId: string): void => {
    const currentLine = filterLines.find((line) => line.id === id);
    if (!currentLine) return;

    const oldField = FILTER_FIELD_DESCRIPTORS.find(
      (f) => f.id === currentLine.field
    );
    const newField = FILTER_FIELD_DESCRIPTORS.find((f) => f.id === newFieldId);

    // First update the field
    updateFilterLine(id, "field", newFieldId);

    // Then clear or validate the value based on field type change
    if (oldField?.type !== newField?.type) {
      // Types are different, clear the value
      updateFilterLine(id, "value", "");
    } else if (newField?.type === "number" && currentLine.value) {
      // Same type (number), but validate existing value
      if (isNaN(Number(currentLine.value))) {
        updateFilterLine(id, "value", "");
      }
    } else if (newField?.type === "date" && currentLine.dateValue) {
      // Same type (date), but validate existing value
      if (isNaN(currentLine.dateValue?.getTime() || NaN)) {
        updateFilterLine(id, "value", "");
      }
    }
  };

  const handleDateChange = (id: number, dateValue: Date | null) => {
    const displayDate = DateUtils.dateToDisplayString(dateValue);
    if (displayDate) {
      updateFilterLine(id, "value", displayDate, dateValue!);
    } else {
      updateFilterLine(id, "value", "");
    }
  };

  const getAvailableFields = (
    currentLineId: number
  ): FilterFieldDescriptor[] => {
    return FILTER_FIELD_DESCRIPTORS.filter(
      (field) =>
        !filterLines.some(
          (line) => line.field === field.id && line.id !== currentLineId
        )
    );
  };

  // Update filter config whenever filter lines change
  useEffect(() => {
    const newFilters = filterLines.reduce((acc, line) => {
      if (line.field && line.value) {
        acc[line.field] = line.value;
      }
      return acc;
    }, {} as Record<string, string>);

    setFilterConfig((prev) => ({
      ...prev,
      filters: newFilters,
    }));
  }, [filterLines]);

  // Notify parent of filter changes
  useEffect(() => {
    onApplyFilters(filterConfig);
  }, [filterConfig, onApplyFilters]);

  const renderSelectBox = (
    line: FilterLine,
    descriptor: FilterFieldDescriptor
  ) => {
    return (
      <SelectBox
        label=""
        selection={getEnumeratorSelection(descriptor.enumId || "", line.value)}
        enumerator={getEnumerator(descriptor.enumId || "")}
        id={`filter-select-${line.id}`}
        getSelectedValue={(value) =>
          handleInputChange(line.id, descriptor, (value?.id || "") as string)
        }
        AutocompleteProps={{
          className: "inputTextFieldForFilterSelect",
        }}
      />
    );
  };

  const renderDateTimeInput = (line: FilterLine) => {
    return (
      <DatePickerElement
        className="datePickerForFilterDialog"
        label=""
        defaultValue={line.dateValue}
        getSelectedValue={(value) => handleDateChange(line.id, value)}
        id="datum-von"
        TypographyProps={{
          className:
            "anwendernachrichten__Label anwendernachrichten__TextStyle",
        }}
        TextFieldProps={{
          className: "logfile__inputTextFieldForDate uiElement__InputWithBox",
        }}
      />
    );
  };

  const renderTextValueInput = (
    line: FilterLine,
    descriptor: FilterFieldDescriptor
  ) => {
    return (
      <TextValue
        value={line.value}
        type={descriptor.type}
        TextFieldProps={{
          className: "inputTextFieldForFilterValue",
        }}
        onChange={(event) =>
          handleInputChange(line.id, descriptor, event.target.value)
        }
        label=""
      />
    );
  };

  const renderValueInput = (line: FilterLine) => {
    const descriptor = FILTER_FIELD_DESCRIPTORS.find(
      (f) => f.id === line.field
    );
    if (!descriptor) return null;

    switch (descriptor.type) {
      case "select":
        return renderSelectBox(line, descriptor);

      case "date":
        return renderDateTimeInput(line);

      default:
        return renderTextValueInput(line, descriptor);
    }
  };

  return (
    <Grid container spacing={3}>
      {filterLines.length > 1 && (
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{
              backgroundColor: "action.hover",
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <Typography className="dialog_FilterLogicLabel">
              {MESSAGES.UNPLAUSIBLE_RECHNUNGEN_FILTER_LOGIC}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                className="dialog_FilterLogicLabel"
                color={
                  filterConfig.logic === "AND" ? "primary" : "text.secondary"
                }
              >
                AND
                <JoinInnerIcon sx={{ fontSize: 20 }} />
              </Typography>

              <Switch
                size="small"
                checked={filterConfig.logic === "OR"}
                onChange={(e) =>
                  handleLogicChange(e.target.checked ? "OR" : "AND")
                }
                sx={{
                  "& .MuiSwitch-track": {
                    backgroundColor: "action.disabled",
                  },
                }}
              />

              <Typography
                className="dialog_FilterLogicLabel"
                color={
                  filterConfig.logic === "OR" ? "primary" : "text.secondary"
                }
              >
                <JoinFullIcon sx={{ fontSize: 20 }} />
                OR
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      )}

      <Grid item xs={12}>
        {filterLines.map((line) => (
          <Stack
            key={line.id}
            direction="row"
            spacing={2}
            sx={{ marginBottom: 2 }}
          >
            <SelectBox
              label={""}
              selection={
                getAvailableFields(line.id)
                  .filter((field) => field.id === line.field)
                  .map((field) => {
                    return {
                      id: field.id,
                      source: field,
                      value: field.label,
                    } as EnumeratorItem;
                  })[0]
              }
              enumerator={
                {
                  items: getAvailableFields(line.id).map((field) => {
                    return {
                      id: field.id,
                      source: field,
                      value: field.label,
                    } as EnumeratorItem;
                  }),
                } as Enumerator
              }
              id="UnplausibleRechnungenFilterSources"
              getSelectedValue={(value) =>
                handleSelectChange(line.id, (value?.id ?? "") as string)
              }
              AutocompleteProps={{
                className: "inputTextFieldForFilterSelect",
              }}
            />

            {renderValueInput(line)}

            {line.value !== "" && (
              <IconButton
                color="secondary"
                size="small"
                onClick={() => removeFilterLine(line.id)}
                disabled={line.value === ""}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Stack>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          {FILTER_FIELD_DESCRIPTORS.length > filterLines.length && (
            <Button
              variant="outlined"
              size="small"
              onClick={addFilterLine}
              disabled={filterLines.some((line) => !line.field || !line.value)}
              startIcon={<AddIcon />}
            >
              {MESSAGES.UNPLAUSIBLE_RECHNUNGEN_ADD_FILTER_LINE}
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default UnplausibleRechnungenFilterDialogContent;
